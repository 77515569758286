import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import type { AppProps } from "next/app";
import { useMemo } from "react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { CHAIN } from "../constants/env";
import "../styles/globals.css";

function App({ Component, pageProps }: AppProps) {
  const { chains, provider } = useMemo(() => configureChains([CHAIN], [publicProvider()]), []);

  const connectors = useMemo(
    () =>
      getDefaultWallets({
        appName: "frankensteintown.wtf",
        chains,
      }).connectors,
    [chains],
  );

  const wagmiClient = useMemo(
    () =>
      createClient({
        autoConnect: true,
        connectors,
        provider,
      }),
    [connectors, provider],
  );

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <Component {...pageProps} />
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
